/* Import custom fonts from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;600&display=swap');

/* Tailwind CSS directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global styles */
/* Global styles */
:root {
  background-color: white;
  font-family: 'Montserrat', sans-serif; /* Default font family */
  color: #0434ff;
}

text {
  font-family: 'Roboto', serif; /* Use Lora for headings */
}
